import Player from "@vimeo/player";




$(document).ready(function() {
  
  Webflow.push(function() {
    $(".w-condition-invisible").remove();
      $(".w-dyn-empty").parent().parent().parent().remove();
    });
    Webflow.ready();
    Webflow.require('slider').redraw();


  // Video related

  if ($(".video-cta").length) {
    var iframe = document.querySelector("iframe");
    var player = new Player(iframe);

    // player.on("play", function() {
    //   console.log("played the video!");
    // });

    $(".video-close").on("click", function() {
      player.pause();
    });
    $(".video-cta").on("click", function() {
      player.play();
    });
  }
  // Slide related
  $.fn.initSlider = function() {
    var me = this;
   // console.log(me)

    var updateCounter = function(target) {
   
      var current = target.find(".w-slider-dot.w-active").index()+1;
      var total = target.find(".w-slide").children().length;

      target.find(".indicator").text(current +"/"+ total);
    };

    //this.total = this.find(".slide-nav").children().length;

    var r = this.find(".w-slider-arrow-left");
    var l = this.find(".w-slider-arrow-right");

    // this.find(".slide-nav")
    //   .children(".w-active")
    //   .index() +
    //   "1 /" +
    //   this.total;
    l.hide();
    r.hide();

    updateCounter(this);

    this.on("click", ".k-slider-nav .slider-left", function() {
      l.trigger("tap");
      updateCounter(me);
    });

    this.on("click", ".k-slider-nav .slider-right", function() {
      r.trigger("tap");
      updateCounter(me);
    });
  };

 
 
  $('.slider').each(function() {
    $(this).initSlider();
});
});
